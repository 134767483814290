import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Subtitle from '@/components/common/Subtitle'
import Typography from '@/components/common/Typography'
import Card from './LeadCard'

const OthersLead = props => (
  <StaticQuery
    query={query}
    render={ data => {
      const products = data.allOthersListYaml.edges.map(el => el.node)
      return (
        <Root theme={props.theme}>
          <Head>
            <Subtitle variant="h5" component="h2" lang="en" color="primary">OTHERS</Subtitle>
            <Title variant="h2" component="h3">その他事業・関連会社</Title>
          </Head>
          <Main>
            <List>
              <List_Parent>
                {products.map((product, index) => (
                  <List_Child key={index}>
                    <Card {...product} />
                  </List_Child>
                ))}
              </List_Parent>
            </List>
          </Main>
        </Root>
      )
    }}
  />
)

export default ThemeConsumer(OthersLead)

const Root = styled.div`
`

const Head = styled.div`
  text-align: center;
  @media ${mq.and(tablet, mobile)} {
    text-align: left;
  }
`

const Main = styled.div`
  margin-top: 36px;
`

const Title = styled(Typography)`
  margin-top: 16px;
`

const List = styled.div``

const List_Parent = styled.ul`
  margin-top: -16px;
`

const List_Child = styled.li`
  margin-top: 16px;
  @media ${mq.and(mobile)} {
    margin-top: 8px;
  }
`

const query = graphql`
  query OthersLeadQuery {
    allOthersListYaml {
      edges {
        node {
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          name
          description
          link
        }
      }
    }
  }
`

import React from 'react'
import styled from '@emotion/styled'
import { graphql, StaticQuery, Link } from 'gatsby'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import LinkButton1 from '@/components/common/LinkButton1'
import Image from '@/components/common/Image'

const ServiceLead = props => (
  <StaticQuery
    query={query}
    render={ data => {
      return (
        <Root theme={props.theme}>
          <Main>
            <Left>
              <Section
                subtitle="WEB PRODUCTION"
                title="ビジネスを成長させるための、実用的なWEBサイトを作ります。"
                body="集客／お問い合わせ・資料ダウンロード数増加／売上増加など、戦略の一部として正しく機能するWEBサイトを制作します。広告運用やデータ解析など、継続的な運用支援も可能です。"
              />
              <Button1 component={Link} to="/web/" type="contrast">さらに詳しく</Button1>
            </Left>
            <Right>
              <Thumbnail fluid={data.image.childImageSharp.fluid} />
            </Right>
          </Main>
          <Foot>
            <Button2 component={Link} to="/web/" type="contrast">さらに詳しく</Button2>
          </Foot>
        </Root>
      )
    }}
  />
)

export default ThemeConsumer(ServiceLead)

const Root = styled.div`
`

const Main = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: center;
  }
`

const Left = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
    padding-right: 40px;
  }
`

const Right = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 40px;
  }
`

const Foot = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: none;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 40px;
    text-align: center;
  }
`

const Thumbnail = styled(Image)`
`

const Button1 = styled(LinkButton1)`
  margin-top: 64px;
  width: 278px;
  @media ${mq.and(tabletS, mobile)} {
    display: none;
  }
`

const Button2 = styled(LinkButton1)`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: none;
  }
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const query = graphql`
  query WebLeadQuery {
    image: file(relativePath: {eq: "web-thumb.jpg"}) {
      id
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import ConceptKV from '@/components/modules/Concept/KV'
import WebLead from '@/components/modules/Web/Lead'
import OutdoorLead from '@/components/modules/Outdoor/Lead'
import OthersLead from '@/components/modules/Others/Lead'
import NewsLead from '@/components/modules/News/Lead'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import RecruitLead from '@/components/modules/Recruit/Lead'

const IndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const siteMeta = data.site.siteMetadata
      const company = data.company.edges[0].node
      return (
        <Root>
          <SEO ogType="website" location={props.location}/>
          <LD json={{
            '@type': 'WebSite',
            'name': siteMeta.title,
            'description': siteMeta.description,
            'url': `${siteMeta.siteUrl}/`
          }}/>
          <LD json={LDEntity.Corporation(siteMeta, company)}/>
          <Breadcrumb path={props.location.pathname} />
          <Head>
            <ConceptKV />
          </Head>
          <Main>
            <Row><WebLead /></Row>
            <Row><OutdoorLead /></Row>
            <Row><OthersLead /></Row>
            <Row><NewsLead /></Row>
            <Row css={styles.Contact}><ContactLead /></Row>
            <Row css={styles.Recruit}><RecruitLead /></Row>
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div`
`

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  padding: 80px 0;
  @media ${mq.and(mobile)} {
    padding: 40px 0;
  }
`

const styles = {
  Contact: css`
    padding: 80px 0 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(IndexPage)

const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
      }
    }
    company: allCompanyInfoYaml {
      edges {
        node {
          name
          legalName
          telephone
          email
          address {
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
        }
      }
    }
  }
`

import React from 'react'
import styled from '@emotion/styled'

const DefList = props => {
  const { children, ...other } = props
  return (
    <Root
      {...other}
    >
      <List>{children}</List>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
`

const List = styled.ul`
`

export default DefList

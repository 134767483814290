import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const DefListItem = props => {
  const { theme, children, ...other } = props
  return (
    <Root
      theme={theme}
      {...other}
    >
      {children}
    </Root>
  )
}

const Root = styled.div`
  @media ${mq.and(desktop, laptop)} {
    width: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 24px 32px;
  }
  @media ${mq.and(tablet, mobile)} {
    margin-top: 4px;
  }
`

export default ThemeConsumer(DefListItem)

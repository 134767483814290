import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Image from '@/components/common/Image'
import Typography from '@/components/common/Typography'
import LinkButton1 from '@/components/common/LinkButton1'

const OthersLeadCard = props => (
  <Root theme={props.theme}>
    <Left>
      <Screenshot fluid={props.image.childImageSharp.fluid} />
    </Left>
    <Right color={props.theme.scale[800]}>
      <Right_Main>
        <Name variant="h3" component="h4">{props.name}</Name>
        <Description variant="body1">{props.description}</Description>
      </Right_Main>
      <Right_Foot>
        <Button component="a" href={props.link} target="_blank" type="contrast">詳細ページへ</Button>
      </Right_Foot>
    </Right>
  </Root>
)

export default ThemeConsumer(OthersLeadCard)

const Root = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: stretch;
  }
`

const Left = styled.div`
  position: relative;
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
    flex-shrink: 0;
    padding: 40px;
  }
  @media ${mq.and(tabletS, mobile)} {
    padding: 20px 0 0;
  }
`

const Right = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    flex-shrink: 0;
    padding: 40px;
  }
  @media ${mq.and(tabletS)} {
    padding: 40px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 32px 16px;
  }
`

const Right_Main = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const Right_Foot = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    text-align: right;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 40px;
    text-align: center;
  }
`

const Screenshot = styled(Image)`
  max-width: 480px;
  margin: 0 auto;
`

const Name = styled(Typography)`
`

const Description = styled(Typography)`
  margin-top: 24px;
`

const Button = styled(LinkButton1)`
  margin-top: 64px;
  width: 278px;
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 0;
  }
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`
